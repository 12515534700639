$theme-colors: (
  "primary": #2f4050,
  "info": #37adf8,
  "warning": #ffc107,
  "danger": #dc3545,
  "success": #28a745,
);
@import "~bootstrap/scss/bootstrap";
.app {
  height: 100vh;
  width: 100vw;
  background-color: #2f4050;
  background-image: url("../../assets/solgbg.png");
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: auto;
  .app-inner {
    background: 0 0;
    margin: 0;
    min-height: 100vh;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    .app-header {
      img {
        max-width: 235px;
        width: 100%;
      }
    }
    .app-body {
      background-color: #fff;
      margin-top: 30px;
      padding: 30px;
      border-radius: 4px;
      width: 100%;
      max-width: 430px;
    }
  }
}
