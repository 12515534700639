.upload {
  height: 100%;
  .loading {
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;
    text-align: center;
    margin: auto auto;
  }
}
