.uploadForm {
  display: block;
  .formGroup {
    margin-bottom: 25px;
    position: relative;
  }
  .buttonRow {
    text-align: right;
  }

  .required:after {
    content: " *";
    color: red;
  }
}
